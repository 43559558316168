<template>
  <div>
    <page-header-admin class="mb-2" :title="$t('Pending applications')" />
    <b-card
      no-body
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Records') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                type = "search"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
              />
              
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        :items="mentorApplications"
        responsive
        :fields="fields"
        :filter="searchQuery"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="''"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
        no-provider-sorting
        no-provider-paging
        no-provider-filtering
        no-sort-reset
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template #head()="{label}">
          {{ label | upper }}
        </template>

        <!-- Column: photo -->
        <template #cell(photo_full_path)="data">
          <b-avatar
            class="grayscale-avatar"
            :src="data.item.user.photo_full_path"
          />
        </template>

        <!-- Column: fullname -->
        <!-- eslint-disable-next-line -->
        <template #cell(user.surname)="data">
          <b-link :to="{ name: 'admin-pending-application', params: { id: data.item.id } }">
            {{ getMentorVisibleFullName(data.item) }}
          </b-link>
        </template>

        <!-- Column: created_at -->
        <template #cell(created_at)="{item}">
          {{ item.created_at | formatGreekDate({ month: '2-digit', year: 'numeric', day: '2-digit'  }) }}
        </template>

        <!-- Column: status -->
        <template #cell(status)="data">
          <template v-if="data.item.status == mentorStatuses.MENTOR_STATUS_NEW">
            <b-badge
              pill
              variant="light-warning"
            >
              {{ $t('New') }}
            </b-badge>
          </template>
          <template v-if="data.item.status == mentorStatuses.MENTOR_STATUS_AWAITING_RESPONSE">
            <b-badge
              pill
              variant="light-info"
            >
              {{ $t('Awaiting response') }}
            </b-badge>
          </template>
          <template v-if="data.item.status == mentorStatuses.MENTOR_STATUS_REJECTED">
            <b-badge
              pill
              variant="light-danger"
            >
              {{ $t('Rejected') }}
            </b-badge>
          </template>
        </template>

        <!-- Column: actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body"
              />
            </template>
            <b-dropdown-item @click="approveApplication(data.item)" >
              {{ $t('Approve application') }}
            </b-dropdown-item>
            <b-dropdown-item @click="rejectApplication(data.item)">
              {{ $t('Reject application') }}
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
    <div>
      <b-row>
        <b-col
          class="d-flex justify-content-end"
        >
          <b-pagination
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            :per-page="perPage"
            v-model="currentPage"
            first-number
            last-number
            :total-rows="totalRows"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BLink,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { useMentorRepository, useMentorHelpers } from '@mentoring-platform/composables'
import { upper, formatGreekDate } from '@mentoring-platform/utils/filters'
import { mentorStatuses } from '@mentoring-platform/constants'
import PageHeaderAdmin from '@mentoring-platform/views/components/blocks/PageHeaderAdmin.vue'

export default {
  components: {
    BAvatar,
    BBadge,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BLink,
    BPagination,
    BRow,
    BTable,
    PageHeaderAdmin,
    vSelect
  },
  filters: {
    formatGreekDate,
    upper
  },
  data() {
    return {
      mentorStatuses,
      perPage: 50,
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1,
      searchQuery: null,
      sortBy: 'created_at',
      isSortDirDesc: true
    }
  },
  computed: {
    fields() {
      return [{
        key: 'photo_full_path',
        label: this.$t('Photo')
      },
      {
        key: 'user.surname',
        label: this.$t('Full name'),
        sortable: true,
      },
      {
        key: 'created_at',
        label: this.$t('Date'),
        sortable: true,
      },
      {
        key: 'status',
        label: this.$t('Status'),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t('Actions')
      }]
    },
    totalRows() {
      return this.mentorApplications.length
    }
  },
  methods: {
    approveApplication(mentorApplication) {
      const payload = {
        application_id: mentorApplication.id,
        status: mentorStatuses.MENTOR_STATUS_ACTIVE
      }
      this.$store.dispatch('mentor/approveApplication', payload)
        .then(response => {
          this.$root.$bvToast.toast(this.$t('Pending mentor application approved'), {
            title: this.$t('Confirmation'),
            variant: 'success',
            solid: true,
          })
          this.refreshMentorApplications()
          this.refreshMentors()
        })
        .catch(error => {
          this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
            title: this.$t('Caution'),
            variant: 'danger',
            solid: true,
          })
        })
    },
    rejectApplication(mentorApplication) {
      const payload = {
        application_id: mentorApplication.id,
        status: mentorStatuses.MENTOR_STATUS_REJECTED
      }
      this.$store.dispatch('mentor/rejectApplication', payload)
        .then(response => {
          this.$root.$bvToast.toast(this.$t('Pending mentor application rejected'), {
            title: this.$t('Confirmation'),
            variant: 'success',
            solid: true,
          })
          this.refreshMentorApplications()
          this.refreshMentors()
        })
        .catch(error => {
          this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
            title: this.$t('Caution'),
            variant: 'danger',
            solid: true,
          })
        })
    },
  },
  setup() {
    const {
      getMentorVisibleFullName
    } = useMentorHelpers()
    const {
      mentorApplications,
      getMentorApplications,
      refreshMentorApplications,
      refreshMentors
    } = useMentorRepository()
    refreshMentorApplications()

    return {
      getMentorVisibleFullName,
      mentorApplications,
      refreshMentorApplications,
      refreshMentors
    }
  }
}
</script>